import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

const Frame = styled.div`
  height: 640px;
  position: relative;
  width: 330px;
  display: inline-block;
  width: 260px;
  height: 530px;
  @media ${(p) => p.theme.sm} {
    width: 260px;
    height: 530px;
  }
`;

const ImgFrame = styled.img`
  height: 640px;
  position: absolute;
  z-index: 5;
  left: 0;
  height: 520px;

  @media ${(p) => p.theme.sm} {
    height: 520px;
  }
`;

const Img = styled.img`
  position: absolute;
  z-index: 1;
  height: 600px;
  top: 3.5%;
  left: 6.5%;
  transition: opacity 0.3s;
  height: 489px;
  left: 20px;
  top: 15px;
  @media ${(p) => p.theme.sm} {
    height: 489px;
    left: 20px;
    top: 15px;
  }
`;

const BlackDiv = styled.div`
  position: absolute;
  width: 280px;
  height: 600px;
  background: black;
  top: 3.5%;
  left: 6.5%;
  height: 483px;
  width: 230px;

  @media ${(p) => p.theme.sm} {
    height: 483px;
    width: 230px;
  }
`;

export default function ReelImage() {
  const [key, setKey] = useState(1);

  useEffect(() => {
    let currentKey = 1;
    const interval = setInterval(() => {
      if (currentKey === 3) {
        setKey(1);
        currentKey = 1;
      } else {
        currentKey += 1;
        setKey(currentKey);
      }
    }, 2500);
    return () => clearInterval(interval);
  }, []);

  return (
    <Frame>
      <ImgFrame src="/reel/frame.png" alt="rewarp sample" />
      <Img src="/reel/1.jpg" style={{ opacity: key >= 1 ? 1 : 0 }} />
      <Img src="/reel/2.jpg" style={{ opacity: key >= 2 ? 1 : 0 }} />
      <Img src="/reel/3.jpg" style={{ opacity: key >= 3 ? 1 : 0 }} />
      <BlackDiv />
    </Frame>
  );
}
