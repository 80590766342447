import React from 'react';
import styled from 'styled-components';

const Root = styled.div`
  margin-top: 20px;
  margin-bottom: 20px;
  width: 30%;
  padding: 0 2%;
  display: flex;
  flex-shrink: 0;
  flex-direction: column;
  align-items: center;
  @media ${(p) => p.theme.sm} {
    width: 100%;
    padding: 0;
  }
`;

const Icon = styled.div`
  background: ${(p) => p.background};
  width: 80px;
  height: 80px;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
  img {
    width: 40px;
  }
`;

const Title = styled.h3`
  color: ${(p) => p.theme.dark};
  font-weight: bold;
  margin-bottom: 10px;
`;

const Content = styled.div`
  color: ${(p) => p.theme.light};
  line-height: 1.6;
`;

const Feature = ({ title, children, icon, color }) => {
  return (
    <Root>
      <Icon background={color}>
        <img src={`/features/${icon}.png`} alt={title} />
      </Icon>
      <Title>{title}</Title>
      <Content>{children}</Content>
    </Root>
  );
};

export default Feature;
