import React from 'react';
import styled, { css } from 'styled-components';
import Link from '../components/Link';
import Layout from '../components/Layout';
import Feature from '../components/Feature';
import SEO from '../components/SEO';
import Container from '../components/Container';
import ReelImage from '../components/ReelImage';

const Section = styled.section`
  padding: 50px 0;
  text-align: center;

  p {
    color: ${(p) => p.theme.light};
    margin-bottom: 20px;
  }

  ${(p) =>
    p.dark &&
    css`
      background: #f7f6fe;
    `};

  @media ${(p) => p.theme.lg} {
    padding: 80px 0;
  }
`;

const Features = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: center;
`;

const Separator = styled.hr`
  border: none;
  border-bottom: 1px solid ${(p) => p.theme.border};
`;

const StyledFrame = styled.iframe`
  margin: auto;
  max-width: 720px;
  width: 100%;
  height: 220px;
  margin-top: 3rem;
  @media ${(p) => p.theme.lg} {
    width: 100%;
    height: 400px;
  }
`;

export default function Index() {
  return (
    <Layout>
      <SEO title="Detect Photoshopped Photos, backed by AI" />
      <Section>
        <Container>
          <ReelImage />
          <h1 style={{ marginTop: 40 }}>
            Detect face <strong>manipulations</strong>. Effortless.
          </h1>
          <p style={{ maxWidth: 600, margin: 'auto' }}>
            Rewarp is an AI backed iOS app which detects and undo-es image
            manipulations performed by Photoshop Liquify, Airbrush and similar
            tools.
          </p>
          <div style={{ marginTop: 60 }}>
            <Link
              to="https://apps.apple.com/app/id1529088595"
              button={1}
              primary={1}
              style={{ marginRight: 5 }}
            >
              Download
            </Link>
            <Link to="/faqs" button={1} outline={1}>
              Learn More
            </Link>
          </div>
        </Container>
      </Section>
      <Separator />

      <Section>
        <Container>
          <h1>Features</h1>
          <Features>
            <Feature title="AI Backed" icon="ai" color="#DAE7F9">
              Rewarp app core is a trained Artificial Intelligence Model used
              for detecting image alterations.
            </Feature>
            <Feature title="Revert Edits" icon="undo" color="#FFF7F8">
              Travel back in time and reconstruct the original image, before
              author made modifications.
            </Feature>
          </Features>
          <Features>
            <Feature
              title="Highlight Modifications"
              icon="warp"
              color="#EDF9F2"
            >
              Heatmap overlay allows you to easily eyeball altered image
              details.
            </Feature>
            <Feature title="Completely Offline" icon="no-wifi" color="#FFF9EA">
              App utilizes iOS CoreML capabilities and does not rely on an
              external server, nor does it require an internet connection.
            </Feature>
          </Features>
          {/* <Features>
            <Feature title="Privacy oriented" icon="no-wifi" color="#FFF9EA">
              We do not collect or store <strong>any</strong> user data. 
            </Feature>
          </Features> */}
        </Container>
      </Section>
      <Separator />

      <Section>
        <Container>
          <h1>Video or it didn't happen!</h1>
          <p style={{ maxWidth: 600, margin: 'auto' }}>
            Check our short demonstration, from editing with Photoshop Liquify
            to undo-ing the edits in Rewarp App.
          </p>
          <StyledFrame
            src="https://www.youtube.com/embed/ZvZhzoqYlTg"
            title="Rewarp app"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          />
        </Container>
      </Section>

      <Separator />
      <Section style={{ paddingBottom: 0 }}>
        <h1>Gallery</h1>
        <p>
          Check examples and results in our <a href="/samples">gallery</a>.
        </p>
        <Link to="/samples" style={{ border: 'none' }}>
          <img src="/gallery.png" alt="Gallery" style={{ width: '100%' }} />
        </Link>
      </Section>
      <Section dark>
        <Container>
          <h1>Download</h1>
          <p style={{ marginBottom: 50 }}>Download the app for free.</p>
          <Link
            to="https://apps.apple.com/app/id1529088595"
            button={1}
            primary={1}
          >
            Download
          </Link>
        </Container>
      </Section>
    </Layout>
  );
}
